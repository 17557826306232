import React from "react"
import { localize } from "../../Atoms/Language"

const BenefitsOfModularWash = () => (
  <section className="mt8 mb8">
    <h2 className="header4">{localize("270")}</h2>
    <p className="body gray600">{localize("271")}</p>
    <p className="body gray600">{localize("272")}</p>
  </section>
)

export default BenefitsOfModularWash
