import React from "react"
import ImageWithText from "../../Molecules/ImageWithText/ImageWithText"
import { graphql, useStaticQuery } from "gatsby"
import { localize } from "../../Atoms/Language"

const imageQuery = graphql`
  {
    file(name: { eq: "carwash4" }) {
      childImageSharp {
        fixed(height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const ModularWashesContent = () => {
  const data = useStaticQuery(imageQuery)

  return (
    <>
      <ImageWithText className="mt8" fixed={data.file.childImageSharp.fixed} imageAltText={localize("4")}>
        <h1 className="header2">{localize("4")}</h1>
        <p className="body">{localize("265")}</p>
        <p className="body">{localize("266")}</p>
        <p className="body">{localize("267")}</p>
      </ImageWithText>
      <p className="body gray600">{localize("268")}</p>
      <p className="body gray600">{localize("269")}</p>
    </>
  )
}
export default ModularWashesContent
