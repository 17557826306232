import React from "react"
import { localize } from "../../Atoms/Language"

const ModularWashForYou = () => (
  <section className="mb16">
    <h2 className="header4">{localize("273")}</h2>
    <p className="body gray600">{localize("274")}</p>
    <p className="body gray600">{localize("275")}</p>
  </section>
)

export default ModularWashForYou
