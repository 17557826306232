import React from "react"
import ModularWashesContent from "../components/Organisms/StaticSections/ModularWashesContent"
import BenefitsOfModularWash from "../components/Organisms/StaticSections/BenefitsOfModularWash"
import ModularWashForYou from "../components/Organisms/StaticSections/ModularWashForYou"
import CTABanner from "../components/Organisms/CTABanner/CTABanner"
import {WithIntl} from "../components/Atoms/withTranslate"
import SEO from "../components/seo"
import { localize } from "../components/Atoms/Language"

const ModularWashesPage = ({language}) => (
  <>
    <SEO
      lang={language}
      title={localize("4")}
      description={localize("276")}
    />
    <div className="container">
      <ModularWashesContent/>
      <BenefitsOfModularWash/>
      <ModularWashForYou/>
      <CTABanner language={language}/>
    </div>
  </>
)

export default WithIntl()(ModularWashesPage)
